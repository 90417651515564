import React from 'react'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { FieldError } from 'react-hook-form'

interface FieldProps {
  label?: string
  error?: FieldError
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 15px;
`

const Field: React.FC<FieldProps> = ({ label, error, children }) => (
  <Container>
    <Typography fontFamily="Montserrat" variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>
      {label}
    </Typography>
    {children}
    <Typography
      variant="subtitle2"
      alignSelf="end"
      color="red"
      fontSize={10}
    >
      {error?.message}
    </Typography>
  </Container>
)

export default Field