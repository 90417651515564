import styled from "@emotion/styled";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  plan: Plan;
}

const PlanButton = styled.button<Omit<Props, "plan" | "discount">>`
  aspect-ratio: 6 / 1;
  padding: 25px;
  flex-direction: column;
  cursor: pointer;

  background: ${(props) =>
    props.selected
      ? "linear-gradient(90deg, #F51593 0%, #FA3271 47%, #F6734B 100%)"
      : "white"};

  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 10px;

  p {
    width: 100%;
    text-align: center;
    margin: unset;
    color: ${(props) => (props.selected ? "white" : "black")};
    font-weight: 700;
    font-size: 14px;
  }

  .value {
    color: ${(props) => (props.selected ? "white" : "gray")};
  }
`;

const PlanOption: React.FC<Props> = ({ selected, plan, ...props }) => (
  <PlanButton {...props} selected={selected}>
    <p>{plan.name}</p>
    <p className="value">
      {plan.months}x de R${Number(plan.value / 100 / plan.months).toFixed(2)}
    </p>
  </PlanButton>
);

export default PlanOption;
