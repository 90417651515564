import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import PlanSelector from '../components/PlanSelector'
import PlanCheckout from '../components/PlanCheckout'
import Header from '../components/Header'
import { BannerDance, BannerHome, BannerPlus, BannerStyle } from '../components/Banners'
import './App.scss'
import Success from '../components/Success'
import Modal from '../components/Modal'
import TermsAndConditions from '../assets/Terms'

const Checkout: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>()
  const [termModal, setTermModal] = useState(false)
  const [success, setSuccess] = useState(false)

  if (success) return <Success onClick={() => setSuccess(false)}/>
  return (
    <section id="checkout">
      <div className="block" style={{ backgroundColor: '#eee', paddingBottom: '50px' }}>
        <PlanSelector
          plan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </div>
      <div className="checkout">
        <PlanCheckout
          plan={selectedPlan}
          setSuccess={setSuccess}
        />
        <span>Ao continuar você concorda com os<br/>
          <button onClick={() => setTermModal(true)}>termos e condições</button>
          {' '}do FitDance Plus.
        </span>
      </div>
      <Modal open={termModal} onClose={() => setTermModal(false)} fullScreen>
        <TermsAndConditions />
      </Modal>
    </section>
  )
}

const App = () => (
  <Router>
    <Header />

    <BannerDance/>
    <BannerHome/>

    <main>
      <Checkout/>
      <aside>
        <BannerStyle/>
        <BannerPlus/>
      </aside>
    </main>
  </Router>
);

export default App