import { useState } from "react";
import { Control, UseFormSetError } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "../../Input";
import { getCoupon } from "../../../services";

interface Props {
  control: Control<any>;
  cost: number;
  errors?: any;
  setDiscount: Function;
  setError: UseFormSetError<any>;
}

const CouponForm: React.FC<Props> = ({
  control,
  errors,
  setDiscount,
  setError,
  cost,
}) => {
  const [loading, setLoading] = useState(false);

  const checkCoupon = async (couponName: string) => {
    setLoading(true);

    if (couponName) {
      try {
        const coupon = await getCoupon(couponName);
        let discountValue = coupon.value ?? (coupon.percentage / 100) * cost;
        setDiscount(discountValue);
      } catch (error) {
        setError("couponName", { message: "Erro ao aplicar cupom!" });
      }
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={loading ? 11 : 12}>
        <Input
          label="CUPOM DE DESCONTO"
          name="couponName"
          control={control}
          error={errors.couponName}
          onBlur={e => checkCoupon(e.target.value)}
        />
      </Grid>
      {loading && (
        <Grid item xs={1}>
          <CircularProgress size={20} sx={{ color: "black" }} />
        </Grid>
      )}
    </Grid>
  );
};

export default CouponForm;