import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

interface Props extends DialogProps {
  open: boolean
  onClose: () => void
  fullScreen?: boolean
}

const Modal: React.FC<Props> = ({
  open,
  onClose,
  fullScreen,
  children,
  ...props
}) => {
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen ? mediaQuery : undefined}
      {...props}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <SvgIcon>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
          </SvgIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        { children }
      </DialogContent>
    </Dialog>
  )
}

export default Modal