import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { checkoutSchema, PaymentMethods } from "./schema";
import Button from "../Button";
import { createNewUser } from "../../services";
import { toast } from "react-toastify";
import UserForm from "../Forms/UserForm";
import CouponForm from "../Forms/CouponForm";
import { useOnUpdate } from "../../hooks/useOnUpdate";
import { gtm } from "../../lib/tagging";

interface Props {
  plan?: Plan;
  setSuccess: Function;
}

const PlanCheckout: React.FC<Props> = ({ plan, setSuccess }) => {
  const { control, handleSubmit, setValue, setError, formState } = useForm<any>(
    {
      mode: "onChange",
      reValidateMode: "onChange",
      criteriaMode: "firstError",
      shouldFocusError: true,
    }
  );

  const focusForm = useRef(true);
  const focusUser = useRef(true);
  const focusPayment = useRef(true);

  const [loading, setLoading] = useState(false);
  const [selectedMethod] = useState(PaymentMethods.CreditCard);
  const [discount, setDiscount] = useState(0);
  const CheckoutForm = checkoutSchema[selectedMethod].container;

  useOnUpdate(() => {
    setValue("couponName", "");
    setDiscount(0);
  }, [plan]);

  const submitCheckout = (data: any) =>
    toast
      .promise(
        async () => {
          if (!plan) return;
          setLoading(true);

          if (data.isNewUser) {
            await createNewUser({
              email: data.email,
              name: data.fullUserName,
              phoneNumber: data.phoneNumber,
              country: "BR",
              password: data.password,
            });
          }

          await checkoutSchema[selectedMethod]
            .submit({
              planId: plan.id,
              ...data,
            })
            .then((res) => {
              gtm("purchase", {
                ...plan,
                subscriptionId: res.subscriptionId,
                discount,
                coupon: data.couponName,
              });
            });

          setSuccess(true);
        },
        {
          pending: "Carregando...",
          error: { render: ({ data }) => data },
        },
        { position: "bottom-left" }
      )
      .finally(() => setLoading(false));

  return (
    <>
      <form onSubmit={handleSubmit(submitCheckout)}>
        <fieldset
          disabled={loading}
          onFocus={() => {
            if (focusForm.current && plan) {
              gtm("begin_checkout", plan);
              focusForm.current = false;
            }
          }}
        >
          <fieldset
            onFocus={() => {
              if (focusUser.current && plan) {
                gtm("add_shipping_info", plan);
                focusUser.current = false;
              }
            }}
          >
            <UserForm
              control={control}
              errors={formState.errors}
              setValue={setValue}
              setError={setError}
            />
          </fieldset>

          <fieldset
            onFocus={() => {
              if (focusPayment.current && plan) {
                gtm("add_payment_info", plan);
                focusPayment.current = false;
              }
            }}
          >
            <CheckoutForm
              control={control}
              errors={formState.errors}
              installments={plan?.months || 0}
              value={plan?.value || 0}
              discount={discount}
            />
          </fieldset>

          <CouponForm
            control={control}
            cost={plan?.value || 0}
            errors={formState.errors}
            setDiscount={setDiscount}
            setError={setError}
          />

          <Button
            type="submit"
            disabled={!plan}
            style={{ margin: "15px 25%", width: "50%" }}
          >
            Confirmar compra
          </Button>
        </fieldset>
      </form>
    </>
  );
};

export default PlanCheckout;
