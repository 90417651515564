import styled from '@emotion/styled'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  gap: 30px;
  background: linear-gradient(250deg, rgba(245,24,146,1) 0%, rgba(255,127,79,1) 100%);
  color: white;
  img { width: 30%; }
`


const BannerPlus = () => (
  <Container>
    <img alt="Plus" src="/plus.png" />

    <div style={{ width: 'fit-content', display: 'inline-block', textAlign: 'center' }}>
      <strong style={{ fontSize: '1.2rem', fontWeight: 700 }}>
        CONHEÇA A EXPERIÊNCIA FITDANCE PLUS+
      </strong>

      <div style={{ display: 'flex' }}>
        <p style={{ fontSize: '0.8rem', flexGrow: 1, width: 0, fontWeight: 300 }}>
        Assine agora e tenha 7 dias experimentais! Conheça os nossos
        conteúdos e aulas ao vivo e viva a experiência FitDance Plus+.
        Caso não aprove, poderá solicitar o cancelamento.
        </p>
      </div>
    </div>
  </Container>
)

export default BannerPlus