// @ts-ignore
const { Iugu } = window

type Card = {
  number: string
  verificationValue: string
  fullName: string
  expiration: string
}

type CardResponse = {
  id: string
  errors: any[]
}

function IuguService() {
  Iugu.setAccountID('8639E36C657B44714840751070A06EBD')
  Iugu.setTestMode(process.env.REACT_APP_ENVIRONMENT !== 'production')

  const generateToken = ({ expiration, fullName, verificationValue, number }: Card) => {
    const [month, year] = expiration.split('/')
    const [firstName, lastName] = fullName.split(' ')

    const card = Iugu.CreditCard(
      number,
      month,
      year,
      firstName,
      lastName,
      verificationValue
    )

    return new Promise(((resolve, reject) => {
      Iugu.createPaymentToken(card, (response: CardResponse) => {
        if (response.errors) return reject(response.errors)
        return resolve(response)
      })
    }))
  }

  return {
    generateToken,
  }
}

export default IuguService()