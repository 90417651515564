import React, { useState, useEffect, useRef } from "react";
import { getPlans } from "../../services";
import PlanOption from "./PlanOption";
import Title from "../Title";
import { gtm } from "../../lib/tagging";
import { useQuery } from "../../hooks/useQuery";

interface Props {
  plan?: Plan;
  setSelectedPlan: Function;
}

const PlanSelector: React.FC<Props> = ({
  plan: selectedPlan,
  setSelectedPlan,
}) => {
  const [plans, setPlans] = useState<Plan[]>();
  const [param, setParam] = useQuery("plan");
  const clickedPlans = useRef<string[]>([]);

  const changePlan = (plan: Plan) => {
    gtm("select_item", plan);
    if (!clickedPlans.current.includes(plan.id)) {
      gtm("add_to_cart", plan);
      clickedPlans.current.push(plan.id);
    }
    setSelectedPlan(plan);
  }

  useEffect(() => {
    (async () => {
      const plans = await getPlans();
      setPlans(plans);

      if (param) {
        const selectedPlan = plans?.find((plan) => plan.name === param);
        if (selectedPlan) changePlan(selectedPlan);
      }
    })();
  }, []);

  return (
    <>
      <Title>Selecione um plano</Title>
      {plans && (
        <div className="options">
          {plans &&
            plans.map((plan) => (
              <PlanOption
                key={plan.id}
                plan={plan}
                selected={selectedPlan?.id === plan.id}
                onClick={() => {
                  changePlan(plan);
                  setParam(plan.name);
                  window.location.hash = "checkout";
                }}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default PlanSelector;
