import Field from "../Field";
import MuiSelect, { SelectProps } from "@mui/material/Select";
import { Control, FieldError, useController } from "react-hook-form";
import styled from "@emotion/styled";
import { memo } from "react";

interface Props extends Omit<SelectProps<any>, "error"> {
  name: string;
  control: Control;
  label?: string;
  error?: FieldError;
}

const StyledSelect = styled(MuiSelect)<Omit<Props, "name" | "control">>`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 5vh !important;
  min-height: 40px;
  padding: 20px;
  color: #142032;
  border: ${(props) => (props.error ? "1px solid red" : "2px solid #C1C3CF")};
`;

const Select = (props: Props) => {
  const { name, control, label, error, ...selectProps } = props;
  const { field } = useController({
    name: `${name}` as const,
    control,
    defaultValue: selectProps.defaultValue,
  });

  return (
    <Field error={error}>
      <StyledSelect {...field} {...selectProps} />
    </Field>
  );
};

export default memo(Select);
