const formatPlan = (item: Plan & Record<string, any>) => ({
  ga4: {
    item_name: item.name,
    item_id: item.id,
    price: item.value / 100,
    currency: "BRL",
    coupon: item.coupon,
    discount: item.discount,
  },
  ga: {
    name: item.name,
    id: item.id,
    price: item.value / 100,
  },
});

const Events: Record<
  string,
  (dataLayer: any, item: Plan & Record<string, any>) => void
> = {
  add_to_cart: (dataLayer, item) => {
    const { ga, ga4 } = formatPlan(item);
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "BRL",
        value: item.value / 100,
        items: [ga4],
      },
    });
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: "BRL",
        add: { products: [ga] },
      },
    });
  },
  select_item: (dataLayer, item) => {
    const { ga4 } = formatPlan(item);
    dataLayer.push({
      event: 'select_item',
      ecommerce: {
        items: [ga4]
      }
    });
  },
  begin_checkout: (dataLayer, item) => {
    const { ga, ga4 } = formatPlan(item);
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "BRL",
        value: item.value / 100,
        items: [ga4],
      },
    });
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'BRL',
        checkout: {
          actionField: { step: 1 },
          products: [ga]
        }
      },
    });
  },
  add_shipping_info: (dataLayer, item) => {
    const { ga, ga4 } = formatPlan(item);
    dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        currency: "BRL",
        value: item.value / 100,
        items: [ga4],
      },
    });
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'BRL',
        checkout: {
          actionField: { step: 2 },
          products: [ga]
        }
      },
    });
  },
  add_payment_info: (dataLayer, item) => {
    const { ga, ga4 } = formatPlan(item);
    dataLayer.push({
      event: "add_payment_info",
      ecommerce: {
        currency: "BRL",
        value: item.value / 100,
        items: [ga4],
      },
    });
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'BRL',
        checkout: {
          actionField: { step: 3 },
          products: [ga]
        }
      },
    });
  },
  purchase: (dataLayer, item) => {
    const { ga, ga4 } = formatPlan(item);
    const price = (item.discount ? item.value - item.discount : item.value) / 100;
    dataLayer.push({
      event: "purchase",
      ecommerce: {
        // GA4
        transaction_id: item.subscriptionId,
        value: price,
        currency: "BRL",
        coupon: item.coupon,
        shipping: 0,
        tax: 0,
        affiliation: 'Landing Page',
        items: [ga4],
        // UA
        currencyCode: 'BRL',
        purchase: {
          actionField: {
            id: `${item.subscriptionId}`,
            coupon: item.coupon,
            revenue: price,
            tax: 0,
            shipping: 0,
            affiliation: 'Landing Page'
          },
          products: [ga]
        }
      },
    });
  },
};

export const gtm = (
  event: keyof typeof Events,
  item: Plan & Record<string, any>
) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ ecommerce: null });

  const fn = Events[event];
  fn((window as any).dataLayer, item);
};