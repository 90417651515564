import styled from '@emotion/styled'

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 60px 0 0 0;
  margin-bottom:-100px;

  img {
      width: 100%;
      height:auto;
    }

  @media screen and (max-width: 820px) {
    img {
      height: 100%;
    }
  }
`

const BannerDance = () => (
  <Container id="dance">
    <img alt="Dançarino" src="/banner-mes.jpg" />
  </Container>
)

export default BannerDance