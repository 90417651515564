import { Control } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { MenuItem } from "@mui/material";
import Input from "../../Input";
import Select from "../../Select";
import Title from "../../Title";

interface Props {
  control: Control<any>;
  errors?: any;
  installments: number;
  value: number;
  discount: number;
}

const installmentValue = (value: number, installments: number) =>
  `R$${Number(value / installments / 100).toFixed(2)}`;

const CreditCardForm: React.FC<Props> = ({
  control,
  installments,
  value,
  discount,
  errors,
}) => {
  return (
    <>
      <Title>Dados de compra</Title>
      <Input
        label="NOME DO TITULAR DO CARTÃO"
        name="fullName"
        autoComplete="off"
        control={control}
        error={errors?.fullName}
      />
      <Input
        label="NÚMERO DO CARTÃO"
        mask="9999 9999 9999 9999"
        name="number"
        autoComplete="off"
        control={control}
        error={errors?.number}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Input
            label="DATA DE VENCIMENTO"
            name="expiration"
            mask="99/99"
            control={control}
            error={errors?.expiration}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label="CVV"
            name="verificationValue"
            mask="999"
            control={control}
            error={errors?.verificationValue}
          />
        </Grid>
      </Grid>
      <Select name="installments" control={control} defaultValue={1}>
        {Array.from({ length: installments }, (_, i) => i + 1)
          .map((installment) => (
            <MenuItem value={installment} key={installment}>
              {discount > 0 && (
                <i style={{ textDecoration: "line-through" }}>
                  {installment}x {installmentValue(value, installment)}
                </i>
              )}
              {"  "}
              {installment}x {installmentValue(value - discount, installment)}
            </MenuItem>
          ))
          .reverse()}
      </Select>
    </>
  );
};

export default CreditCardForm;
