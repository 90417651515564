import styled from '@emotion/styled'

const Button = styled.button`
  background: linear-gradient(90deg, #F51593 0%, #FA3271 47%, #F6734B 100%);
  box-shadow    : 0px 4px 8px #00000029;
  padding: 8px;
  border-radius : 50px;
  color         : #FFFFFF;
  width         : 100%;
  height        : 40px;
  font-family   : 'Montserrat';
  font-size     : 16px;
  line-height   : 24px;
  display       : inline-block;
  text-align    : center;
  border        : none;
  cursor: pointer;
`

export default Button