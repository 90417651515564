import styled from '@emotion/styled'

const Container = styled.section`
  display: grid;
  overflow: auto;
  width: 100%;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 10px;
  padding: 5% 10%;
  background-color: #f8f8f8;
  margin-top:100px;

  @media screen and (max-width: 820px) {
    padding: 6%;
  }
`

const Icon = styled.div<{ src: string }>`
  aspect-ratio: 4 / 3;
  border-radius: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
  text-align: center;
`

const images: { label: React.ReactNode, image: string }[] = [
  { label: <p>+ de 300 aulas e vídeos<br/>1.500 horas de conteúdo</p>, image: '/content11.png' },
  { label: <p>Aplicativo intuitivo<br />e interativo</p>, image: '/content12.png' },
  { label: <p>Acesse via aplicativo<br />e transmita na sua TV</p>, image: '/content13.png' },
]

const BannerHome = () => (
  <Container>
    {images.map(({ label, image }, key) => (
      <Icon src={image} key={key}>
        { label }
      </Icon>
    ))}
  </Container>
)

export default BannerHome