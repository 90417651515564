import { Control, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { memo, useEffect, useState } from 'react'
import * as yup from 'yup'
import Grid from '@mui/material/Grid'
import Input from '../../Input'
import { getUserByEmail } from '../../../services'
import CircularProgress from '@mui/material/CircularProgress'
import Title from '../../Title'

interface Props {
  control: Control<any>
  errors?: any,
  setValue: UseFormSetValue<any>,
  setError: UseFormSetError<any>
}

const testEmail = yup.string().email()

const UserForm: React.FC<Props> = ({ control, errors, setValue, setError }) => {
  const [isNewUser, setIsNewUser] = useState(false)
  const [verifying, setVerifying] = useState(false)

  useEffect(() => {
    setValue('isNewUser', isNewUser)
  }, [isNewUser])

  const checkUser = async (email: string) => {
    setVerifying(true)
    const isEmail = email ? await testEmail.isValid(email) : false
    if (isEmail) {
      const buyer = await getUserByEmail(email)

      if (!buyer) {
        setIsNewUser(true);
        (['fullUserName', 'password', 'confirmPassword']).forEach((field: string) => {
          setError(field, { type: 'required', message: 'Obrigatório!' })
        })
      } else {
        setIsNewUser(false)
      }
    }
    setVerifying(false)
  }

  return (
    <>
      <Title>Dados do comprador</Title>
      <Grid container spacing={1} sx={{ alignItems: 'center' }}>
        <Grid item xs={verifying ? 11 : 12}>
          <Input
            label="E-MAIL"
            name="email"
            type="email"
            control={control}
            error={errors?.email}
            onBlur={e => checkUser(e.target.value)}
          />
        </Grid>
        {verifying &&
        <Grid item xs={1}>
          <CircularProgress size={20} sx={{ color: 'black' }} />
        </Grid>}
      </Grid>

      {isNewUser && <>
        <Input
          label="NOME COMPLETO"
          name="fullUserName"
          control={control}
          error={errors?.fullName}
        />
        <Input
            label="TELEFONE"
            name="phoneNumber"
            control={control}
            mask="(99) 99999-9999"
            error={errors?.phoneNumber}
            required={true}
        />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Input
              label="SENHA"
              name="password"
              type="password"
              control={control}
              error={errors?.password}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label="CONFIRMAR SENHA"
              name="confirmPassword"
              type="password"
              control={control}
              error={errors?.confirmPassword}
            />
          </Grid>
        </Grid>
      </>}
    </>
  )
}

export default memo(UserForm)