import { postCheckout } from '../../services'
import Iugu from '../../services/iugu'
import CreditCardForm from '../Forms/CreditCardForm'
import PixForm from '../Forms/PixForm'

export enum PaymentMethods {
  CreditCard,
  Pix,
}

export const checkoutSchema = {
  [PaymentMethods.CreditCard]: {
    name: 'Cartão de Crédito',
    container: CreditCardForm,
    submit: async (data: any) => {
      const card: any = await Iugu.generateToken(data)
        .catch(() => { throw 'Verifique as informações do cartão!' })
      
      try {
        const response = await postCheckout({
          cardHash: card.id,
          planId: +data.planId,
          installments: data.installments,
          email: data.email,
          couponName: data.couponName
        })

        return response
      } catch(err: any) {
        throw err?.response?.data?.message ?? 'Erro ao processar pagamento!'
      }
    }
  },
  [PaymentMethods.Pix]: {
    name: 'Pix',
    container: PixForm,
    submit: async (data: any) => {}
  },
}
