import api from './api'

interface CheckoutData {
  email:	string
  planId:	number
  cardHash:	string
  installments:	number
  couponName?: string
}

export const getPlans = async (): Promise<Plan[]> => {
  const { data } = await api.get('/plans')
  return data
}

export const postCheckout = async (checkout: CheckoutData) => {
  const { data } = await api.post('/checkout', checkout)
  return data
}

export const getUser = async (userId: number) => {
  const { data } = await api.get(`/users/name/${userId}`);
  return data;
}

export const getUserByEmail = async (email: string) => {
  const { data } = await api.post('/users/email', { email })
  return data
}

export const createNewUser = async (registrationData: User) => {
  const { data } = await api.post('/auth/register', registrationData)
  return data
}

export const getCoupon = async (couponName: string) => {
  const { data } = await api.get(`/coupons/${couponName}`)
  return data
}