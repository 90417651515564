import styled from '@emotion/styled';
import React from 'react';

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  padding: 10px 0;
  position: fixed;
  top: 0;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  img { margin: 0 5% 0 5%; }

  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  a {
    font-size: 0.8rem;
    color: unset;
    text-decoration: none;
  }

  @media screen and (max-width: 700px) {
    a { display: none; }
    nav { display: none; }
  }
`

const Header: React.FC = () => (
  <Container>
    <img alt="Fitdance" src="/fitdance.png" width="70px" />
    <nav>
      <a href="#dance">Viva da dança</a>
      <a href="#home">Dance em casa</a>
      <a href="#style">Use FitDance Style</a>
      <a href="#plus">Faça aulas presenciais</a>
    </nav>
  </Container>
)

export default Header