import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './global.css'

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer autoClose={false}/>
  </React.StrictMode>,
  document.getElementById('root')
)