import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { memo } from 'react';

const Title = styled(Typography)`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  padding: 20px 0;
  font-size: 18px;
`

export default memo(Title)