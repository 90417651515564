type UseQueryHook = (param: string) => [value: string | null, setValue: (value: string) => void]

export const useQuery: UseQueryHook = (param) => {
  const url = new URL(window.location as any)

  const value = url.searchParams.get(param)

  const setValue = (value: string) => {
    url.searchParams.set(param, value)
    window.history.pushState({}, '', url as any)
  }

  return [value, setValue]
}