import styled from '@emotion/styled'

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  font-weight: 300;
  font-size: 13px;

  ul { list-style-type: "✓  "; }

  flex-direction: column;
  padding: 30px;
  img { width: 100%; }
`

const topics = [
  '15% OFF em roupas do FitDance Style',
  'Programas e cursos on-line',
  'Vídeoaula e tutoriais',
  'Câmera frente e costas',
  'Aulas ao vivo',
  'Experiências exclusivas',
  'Conteúdo para corpo e mente',
]

const BannerStyle = () => (
  <Container>
    <p style={{ fontWeight: 'bold', margin: 'unset' }}>
      O que você encontra na plataforma:
    </p>
    <ul>{ topics.map((topic, idx) => <li key={idx}>{ topic }</li>) }</ul>
    <img alt="Style" src="/style.png" />
  </Container>
)

export default BannerStyle