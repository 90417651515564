import { Control } from "react-hook-form"

interface Props {
  control: Control<any>
}

const PixForm: React.FC<Props> = ({ control }) => (
  <div>
    <p>PixForm</p>
  </div>
)

export default PixForm