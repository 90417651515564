import { useEffect, useRef } from "react";

export function useOnUpdate(effect: Function, deps: any[]) {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      effect();
    } else {
      isMounted.current = true;
    }
  }, deps);
}
