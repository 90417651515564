import InputMask, { Props as MaskProps } from "react-input-mask";
import styled from "@emotion/styled";
import Field from "../Field";
import { Control, FieldError, useController } from "react-hook-form";
import { memo } from "react";

interface InputProps {
  name: string;
  control: Control;
  mask?: string;
  label?: string;
  error?: FieldError;
}

const StyledInput = styled(InputMask as any)<
  Omit<MaskProps, "mask"> & Pick<InputProps, "error">
>`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  height: 5vh !important;
  min-height: 40px;
  padding: 20px;
  box-sizing: border-box;
  color: #142032;
  border: ${(props) => (props.errors ? "1px solid red" : "2px solid #C1C3CF")};
`;

const TextInput = (props: InputProps & Omit<MaskProps, "mask">) => {
  const { name, control, label, error, mask, ...maskProps } = props;
  const { field } = useController({ name: `${name}` as const, control, defaultValue: "" });

  return (
    <Field error={error}>
      <StyledInput
        mask={mask ?? ""}
        placeholder={label}
        {...field}
        {...maskProps}
      />
    </Field>
  );
};

export default memo(TextInput);
