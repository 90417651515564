const TermsAndConditions = () => (
  <>
    <p>
      <strong>TERMOS E CONDIÇÕES</strong>
    </p>
    <p>
      <strong>FITDANCE PLUS</strong>
    </p>
    <p>
      Seguem as orientações necessárias para o usuário interessado em aderir ao
      plano de assinatura do FitDance Plus.
    </p>
    <p>
      <strong>FITDANCE PLUS - ASSINATURA SEMESTRAL OU ANUAL</strong>
    </p>
    <p>
      Por ser um serviço de assinatura semestral ou anual, após você realizar a
      aquisição de sua assinatura dos conteúdos exclusivos da FitDance Plus,
      essa assinatura será renovada automaticamente a cada 6 (seis) ou 12 (doze)
      meses, respectivamente, a partir da data inicial de compra, realizando uma
      nova cobrança no mesmo valor e condições de sua primeira compra até que
      seja solicitado a não renovação automática.
    </p>
    <p>
      Para utilizar a FitDance Plus, você precisa ter acesso à internet e um
      aparelho compatível com a plataforma do FitDance Plus+, bem como fornecer
      uma ou mais formas de pagamento. &quot;Forma de pagamento&quot; refere-se
      a uma forma de pagamento atualizada, válida e aceitável, que poderá ser
      atualizada periodicamente, e que poderá incluir o pagamento por meio da
      sua conta com terceiros. A menos que solicite a não renovação automática
      da sua assinatura, você nos autoriza a cobrar o preço da assinatura dos
      próximos 6 (seis) ou 12 (doze) meses usando a mesma forma de pagamento.
    </p>
    <p>
      A FitDance é única e exclusivamente responsável pelo FitDance Plus, em
      todos os aspectos legais e financeiros. Qualquer questão administrativa ou
      judicial quanto ao conteúdo do produto adquirido, suas características,
      qualidade, funcionalidades, oferta e publicidade, deve ser feita
      diretamente à FitDance.
    </p>
    <p>
      Ao concretizar a compra, você concorda e aceita de forma integral e
      irrestrita os Termos e Condições da Plataforma FitDance. Portanto,
      recomenda-se a leitura atenta dos referidos documentos;
    </p>
    <p>
      <strong>Cancelamento do FitDance Plus - Plano de Assinatura</strong>
    </p>
    <p>
      De acordo com o artigo 49 do Código de Defesa do Consumidor, o prazo para
      arrependimento e reembolso de compras feitas à distância é de 7 (sete)
      dias, contados a partir da data da compra / aprovação.
    </p>
    <p>
      Ao adquirir a FitDance Plus, você concorda com todas as normas
      estabelecidas neste presente termo. Para solicitar o cancelamento, você
      deve entrar em contato por e-mail ou por telefone com o SAC da FitDance
      para realizar o procedimento.
    </p>
    <p>
      Ressalta-se que caso você não cancele expressamente o período de
      experiência ao final do período de utilização gratuita, A FitDance,
      responsável pelo produto, passará a cobrar, automaticamente, o pagamento
      referente a assinatura, cuja aquisição será regida pelos presentes Termos
      de Compra.
    </p>
    <p>
      O prazo de cancelamento informado acima passará a valer a partir da
      efetivação da compra. Assim, você, comprador(a), é responsável por
      verificar, imediatamente, se o acesso ao produto digital adquirido foi
      efetivado. Nesse sentido, caso haja algum problema técnico para acessar o
      conteúdo adquirido, o prazo de cancelamento será suspenso até que o
      problema seja resolvido.
    </p>
    <p>
      Em caso de reembolso o comprador poderá ser reembolsado através de
      transferência bancária ou estorno no cartão de crédito, dependendo do meio
      de pagamento usado no momento da compra. O reembolso pode levar cerca de
      trinta dias para acontecer em se tratando de transferência bancária e, de
      30 a 60 dias quando através de estorno pelo cartão de crédito
    </p>
    <p>
      <strong>Acesso</strong>
    </p>
    <p>
      Ao adquirir o programa da FitDance Plus, você terá amplo acesso aos
      conteúdos online, sendo que sua assinatura será renovada automaticamente
      após esse período, a menos que você solicite a não renovação automática ou
      o cancelamento do seu plano. O acesso é pessoal e você não poderá
      acessá-lo em mais de um dispositivo ao mesmo tempo. O acesso simultâneo em
      mais de um dispositivo poderá acarretar em exclusão do seu cadastro sem
      aviso prévio, pois viola as nossas políticas e o presente termo.
    </p>
    <p>
      <strong>Pagamentos</strong>
    </p>
    <p>
      A FitDance Plus é uma programa de aula de dança online que oferece
      conteúdos audiovisuais através da plataforma FitDance, e ao assinar você
      terá acesso por 6 (seis) ou 12 (doze) meses contínuos aos conteúdos do
      programa. O pagamento pode ser feito à vista ou via cartão de crédito,
      podendo parcelar em até 12 vezes sem juros. Ao finalizar sua inscrição
      como assinante você concorda com a forma de pagamento escolhida na hora do
      cadastro e também com a duração e o tempo de acesso que a FitDance Plus
      lhe fornece e descreve, nas páginas do site oficial e nestes termos. O
      parcelamento é uma condição de pagamento e a quantidade de parcelas que
      você escolheu na hora do pagamento não determina o seu tempo de acesso,
      que será de acordo com as regras estabelecidas pela plataforma da
      FitDance.
    </p>
    <p>
      A compra aparecerá no seu extrato ou fatura do cartão como sendo realizada
      na loja xxx. Contudo, ressaltamos que se trata de uma mera identificação
      na fatura ou extrato do cartão, já que este produto digital foi criado
      exclusivamente pela FitDance, que possui responsabilidade pelo seu
      conteúdo, funcionalidade, características, oferta e publicidade;
    </p>
    <p>
      Caso a compra do FitDance Plus tenha sido realizada através do
      parcelamento com cobrança automática, você fica desde já ciente que o
      inadimplemento de qualquer parcela acarretará no bloqueio do acesso ao
      produto adquirido. Além disso, o comprador inadimplente poderá ser
      impedido de realizar novas compras de produtos digitais cadastrados nas
      plataformas FitDance.
    </p>
    <p>
      O prazo de entrega do produto digital pode variar conforme o meio de
      pagamento, exemplo: Boleto Bancário, Cartão de Crédito, Transferência
      Online.
    </p>
    <p>
      <strong>ISENÇÃO DE RESPONSABILIDADE</strong>
    </p>
    <p>
      Ao adquirir um curso online oferecido pela FitDance, o usuário declara
      estar em boa forma física, e atesta que está apto para assistir e executar
      os movimentos propostos no curso. Além disso, o usuário atesta ser maior
      de idade, responsável por meus atos ou estar sob a supervisão e orientação
      de maior responsável.
    </p>
    <p>
      Por se tratar de conteúdo de dança e propor a execução de coreografias, o
      usuário declara que isenta de quaisquer responsabilidades a FitDance e
      assume por minha livre e espontânea vontade todos os riscos envolvidos e
      suas conseqüências nos movimentos coreográficos propostos, que mal
      executados podem originar eventuais lesões provenientes de quedas (como
      deslocamentos, luxações, traumas, fraturas), e qualquer acidente no
      momento da execução, como em qualquer dança.
    </p>
    <p>
      Portanto, declara de livre espontânea vontade ter compreendido e estar
      ciente de todo o teor deste Termo, bem como de meus direitos e obrigações,
      tendo tomado pleno conhecimento do regulamento e da proposta do presente
      curso, comprometendo-se a não recorrer a nenhum órgão ou tribunal, no que
      diz respeito a qualquer punição imputada à FitDance. Isenta assim quem
      quer que seja, de toda e qualquer responsabilidade legal de tudo o que
      vier a ocorrer por consequência do conteúdo do presente no curso online.
    </p>
    <p>
      O usuário cadastrado declara estar ciente destas condições, e aceita os
      termos deste instrumento.
    </p>
    <p>
      Seus dados de contato, como e-mail, telefone, etc., podem ser usados pela
      FitDance, respeitando a relação e destinação das informações.
    </p>
    <p>
      A conclusão da compra confirma a ciência e concordância do comprador
      quanto aos Termos de Compra.
    </p>
    <p>
      <strong>DÚVIDAS E CONTATO</strong>
    </p>
    <p>
      Caso tenha qualquer dúvida em relação ao presente documento, problemas e
      dúvidas sobre a compra, como opções de pagamento, acesso ao produto,
      reembolso e suporte em geral, o usuário poderá entrar em contato através
      do sac@fitdance.com, utilizando no título da mensagem a frase:{' '}
      <em>Termos e Condições - Fitdance Plus.</em>
    </p>
  </>
)

export default TermsAndConditions